import React from "react";

const github = (
  <svg
    width='31'
    height='30'
    viewBox='0 0 31 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.1267 0.725587C6.83822 0.722236 0.125 7.4321 0.125 15.7139C0.125 22.2628 4.32453 27.8298 10.173 29.8743C10.9607 30.072 10.84 29.5123 10.84 29.1302V26.5328C6.29191 27.0657 6.10757 24.0559 5.80258 23.5532C5.18589 22.5008 3.72795 22.2327 4.16365 21.7299C5.19929 21.197 6.25504 21.864 7.47837 23.6705C8.36319 24.981 10.0892 24.7598 10.964 24.5419C11.1551 23.7543 11.5639 23.0505 12.127 22.5042C7.41469 21.6596 5.45066 18.7839 5.45066 15.3653C5.45066 13.7063 5.99697 12.1813 7.06948 10.9513C6.38575 8.92355 7.13316 7.18743 7.2337 6.92936C9.18097 6.75508 11.2053 8.32362 11.3629 8.44763C12.4689 8.14934 13.7324 7.99181 15.1468 7.99181C16.5679 7.99181 17.8348 8.15604 18.9508 8.45768C19.3296 8.16945 21.2064 6.82211 23.0163 6.98634C23.1135 7.24441 23.8441 8.94031 23.2006 10.9412C24.2865 12.1746 24.8396 13.713 24.8396 15.3753C24.8396 18.8007 22.8621 21.6797 18.1364 22.5109C18.5412 22.9089 18.8626 23.3837 19.0818 23.9073C19.3011 24.431 19.4138 24.9931 19.4133 25.5608V29.3313C19.4402 29.633 19.4133 29.9313 19.9161 29.9313C25.8517 27.9304 30.125 22.3232 30.125 15.7172C30.125 7.4321 23.4084 0.725587 15.1267 0.725587Z'
      fill='#424770'
    />
  </svg>
);

const scrollUp = (
  <svg
    width='12'
    height='21'
    viewBox='0 0 24 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.268 0.999998C11.0378 -0.333335 12.9623 -0.333333 13.7321 1L23.2583 17.5C24.0281 18.8333 23.0659 20.5 21.5263 20.5H2.47372C0.934118 20.5 -0.0281307 18.8333 0.74167 17.5L10.268 0.999998Z'
      fill='#2f80ed'
    />
  </svg>
);

const scrollDown = (
  <svg
    width='12'
    height='21'
    viewBox='0 0 24 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.268 20C11.0378 21.3333 12.9623 21.3333 13.7321 20L23.2583 3.5C24.0281 2.16667 23.0659 0.5 21.5263 0.5H2.47372C0.934118 0.5 -0.0281307 2.16667 0.74167 3.5L10.268 20Z'
      fill='#2f80ed'
    />
  </svg>
);

export { github, scrollUp, scrollDown };
